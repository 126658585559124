import { Injectable, signal } from '@angular/core';
import { ILocation } from '../../constants';
import { Router } from '@angular/router';

@Injectable()
export class GetCareService {
  private readonly _tab!: string;
  private readonly _type = signal<string | undefined>(undefined);
  private readonly _account = signal<any>(undefined);
  private readonly _location = signal<ILocation | undefined>(undefined);
  private readonly _action = signal<string | undefined>(undefined);
  private readonly _time = signal<string | undefined>(undefined);
  private readonly _timeSlotId = signal<string | undefined>(undefined);
  private readonly _provider = signal<any | undefined>(undefined);
  private readonly _isPrimeCareOption = signal<boolean | undefined>(undefined);

  constructor(private readonly router: Router) {
    const tabs = this.router.url.split('/');
    this._tab = tabs[1];
  }

  setAccount(account: any): void {
    const acc = account ? { ...account, name: `${account.firstName} ${account.lastName}` } : undefined;
    this._account.set(acc);
    this.updateLocalStorage();
  }

  get account(): any {
    return this._account();
  }

  setType(type: string): void {
    this._type.set(type);
    this.updateLocalStorage();
  }

  get type(): string | undefined {
    return this._type();
  }

  setLocation(location: ILocation): void {
    this._location.set(location);
    this.updateLocalStorage();
  }

  get location(): ILocation | undefined {
    return this._location();
  }

  setAction(action: string): void {
    this._action.set(action);
    this.updateLocalStorage();
  }

  get action(): string | undefined {
    return this._action();
  }

  setTime(time: string): void {
    this._time.set(time);
    this.updateLocalStorage();
  }

  get time(): string | undefined {
    return this._time();
  }

  setProvider(provider: any): void {
    this._provider.set(provider);
    this.updateLocalStorage();
  }

  get provider(): any | undefined {
    return this._provider();
  }

  setTimeSlotId(timeSlotId: string | undefined): void {
    this._timeSlotId.set(timeSlotId);
    this.updateLocalStorage();
  }

  get timeSlotId(): string | undefined {
    return this._timeSlotId();
  }

  setIsPrimeCareOption(isPrime?: boolean): void {
    this._isPrimeCareOption.set(isPrime);
    this.updateLocalStorage();
  }

  get isPrimeCareOption(): boolean | undefined {
    return this._isPrimeCareOption();
  }

  initService(): void {
    const getCare = localStorage.getItem(`${this._tab}-getCare`);
    if (!getCare) return;

    const getCareFlow = JSON.parse(getCare);
    this.setLocation(getCareFlow.location);
    this.setAction(getCareFlow.action);
    this.setTime(getCareFlow.time);
    this.setTimeSlotId(getCareFlow.timeSlotId);
    this.setType(getCareFlow.type);
    this.setAccount(getCareFlow.account);
    this.setProvider(getCareFlow.provider);
    this.setIsPrimeCareOption(getCareFlow.isPrimeCareOption);
  }

  clear(): void {
    localStorage.removeItem(`${this._tab}-getCare`);
    this._account.set(undefined);
    this._type.set(undefined);
    this._location.set(undefined);
    this._action.set(undefined);
    this._time.set(undefined);
    this._timeSlotId.set(undefined);
    this._provider.set(undefined);
    this._isPrimeCareOption.set(undefined);
  }

  private updateLocalStorage(): void {
    const getCare = {
      account: this._account(),
      type: this._type(),
      location: this._location(),
      action: this._action(),
      time: this._time(),
      timeSlotId: this._timeSlotId(),
      provider: this._provider(),
      isPrimeCareOption: this._isPrimeCareOption(),
    };
    localStorage.setItem(`${this._tab}-getCare`, JSON.stringify(getCare));
  }
}
